import { FC, useEffect, useState } from 'react'
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CardMedia,
    Stack
} from '@mui/material'
import {
    CoreTypography,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import BaselineAssessmentDialog from '../BaselineAssessmentDialog'
import { defineMessages, useIntl } from 'react-intl'
import { useIsSmallScreen } from '../../../hooks/useIsSmallScreen'
import { useTryGetLatestAttempt } from '../../../hooks/useTryGetLatestAttempt'

const messages = defineMessages({
    baselineAssessmentCardTitle: {
        defaultMessage: 'Improve your Insights',
        description: 'Title for a call to action to improve user insights.'
    },
    baselineAssessmentCardDescription: {
        defaultMessage: 'Answer a few short questions for improved Insights.',
        description:
            'Sub-title telling the user about the outcomes of taking a short survey.'
    },
    baselineAssessmentButtonLabel: {
        defaultMessage: 'Get started',
        description:
            'Button label prompting the user to take a personalized survey.'
    }
})

const BaselineAssessmentCTA: FC = () => {
    const intl = useIntl()
    const theme = useTheme()
    const isSmallScreen = useIsSmallScreen()

    const {
        loading,
        error,
        refetch,
        latestAttempt,
        getLatestAttemptError,
        isCompletedAttempt
    } = useTryGetLatestAttempt('baselineAssessment', 'baseline')

    const [baselineAssessmentDialogOpen, setBaselineAssessmentDialogOpen] =
        useState<boolean>(false)
    const openBaselineAssessmentDialog = () => {
        setBaselineAssessmentDialogOpen(true)
    }
    const closeBaselineAssessmentDialog = () => {
        refetch().then()
        setBaselineAssessmentDialogOpen(false)
    }

    if (
        isCompletedAttempt ||
        (getLatestAttemptError &&
            getLatestAttemptError?.errorCode ===
                'ASSESSMENT_CMS_ASSESSMENT_NOT_FOUND')
    ) {
        return <></>
    } else {
        return (
            <>
                <Card sx={{ maxWidth: '600px', width: '100%', marginTop: 2 }}>
                    <Stack direction={isSmallScreen ? 'column-reverse' : 'row'}>
                        <Stack>
                            <CardHeader
                                title={
                                    <CoreTypography variant={'h3'}>
                                        {intl.formatMessage(
                                            messages.baselineAssessmentCardTitle
                                        )}
                                    </CoreTypography>
                                }
                                sx={{
                                    padding: theme.spacing(
                                        isSmallScreen ? 2 : 3,
                                        3,
                                        0,
                                        3
                                    )
                                }}
                            />
                            <CardContent
                                sx={{
                                    padding: theme.spacing(2, 3, 0, 3),
                                    height: '100%'
                                }}
                            >
                                <CoreTypography variant={'body1'}>
                                    {intl.formatMessage(
                                        messages.baselineAssessmentCardDescription
                                    )}
                                </CoreTypography>
                            </CardContent>
                            <CardActions
                                sx={{ padding: theme.spacing(2, 3, 3, 3) }}
                            >
                                <Button
                                    variant={'contained'}
                                    color={'primary'}
                                    fullWidth={isSmallScreen}
                                    endIcon={
                                        <LeafIcon
                                            icon={'arrow-right'}
                                            fontSize={'small'}
                                        />
                                    }
                                    onClick={openBaselineAssessmentDialog}
                                >
                                    <CoreTypography
                                        customVariant={'buttonNormal'}
                                    >
                                        {intl.formatMessage(
                                            messages.baselineAssessmentButtonLabel
                                        )}
                                    </CoreTypography>
                                </Button>
                            </CardActions>
                        </Stack>
                        <CardMedia
                            component={'img'}
                            src={
                                'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/9ccb0f9b-07b7-4ce1-8e38-e477dc85a700/cms'
                            }
                            sx={{
                                maxWidth: '300px',
                                width: '100%',
                                alignSelf: 'center',
                                padding: isSmallScreen
                                    ? theme.spacing(3, 2, 0, 2)
                                    : theme.spacing(3, 2, 2, 0)
                            }}
                        />
                    </Stack>
                </Card>
                <BaselineAssessmentDialog
                    open={baselineAssessmentDialogOpen}
                    onClose={closeBaselineAssessmentDialog}
                />
            </>
        )
    }
}

export default BaselineAssessmentCTA
