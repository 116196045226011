import { StackProps, Skeleton, Stack, Button } from '@mui/material'
import {
    CoreTypography,
    ErrorScreenVariant,
    LeafErrorCode,
    LeafIcon,
    ThriveLogoLoading
} from '@thriveglobal/thrive-web-leafkit'
import { FC, useCallback, useState } from 'react'
import BaselineAssessmentItem from '../BaselineAssessmentCard'
import { defineMessages, useIntl } from 'react-intl'
import {
    AssessmentAttemptError,
    AssessmentAttemptSuccess
} from '../../../graphql/generated/autogenerated'
import { useAppSelector } from '@thriveglobal/thrive-web-core'
import { Avo } from '@thriveglobal/thrive-web-tracking'
import { useGetInProgressOrCreateNewAttempt } from '../../../hooks/useGetInProgressOrCreateNewAttempt'

const messages = defineMessages({
    introHeader: {
        defaultMessage: 'First, tell us about yourself',
        description:
            "Introduction text for a survey about the user's well being"
    },
    introSubHeader01: {
        defaultMessage:
            'Answer honestly so we can personalize your experience.',
        description:
            'Asking the user for honest responses to the survey they are about to fill out.'
    },
    introSubHeader02: {
        defaultMessage: 'Remember, there are no right or wrong answers.',
        description:
            'Reminder to the user that there are no incorrect answers to the following survey.'
    },
    introButtonLabel: {
        defaultMessage: 'Get started',
        description: 'Button label indicating start of survey.'
    },
    assessmentItemDescriptor: {
        defaultMessage: 'To what degree does this statement describe you?',
        description:
            "The precursor to a prompt that the user will respond to with an answer of 'Agree' or 'Disagree'."
    },
    currentStepHeader: {
        defaultMessage: '{currentStep} of {totalSteps}',
        description:
            'Describes current step of total steps in process. Ex: 1 of 5'
    },
    errorCodeMessage: {
        defaultMessage:
            "We're having trouble loading this page, please refresh the page or come back later.",
        description:
            'Description of technical difficulties. Asking the user to try again later.'
    },
    outroHeader: {
        defaultMessage: 'Thank you for investing in your well-being.',
        description: 'Thank you message for the end of the survey.'
    },
    outroSubHeader: {
        defaultMessage:
            'Your honest responses will help guide your experience.',
        description: "Sub header describing the impact of the user's action."
    },
    outroButtonLabel: {
        defaultMessage: 'Finish',
        description: 'Label for button that closes the survey.'
    }
})

export interface BaselineAssessmentResponse {
    completed: boolean
    error?: any
    specificError?: AssessmentAttemptError
    assessmentAttempt?: AssessmentAttemptSuccess
}

export interface BaselineAssessmentProps extends StackProps {
    onComplete: (response: BaselineAssessmentResponse) => void
    skipIntro?: boolean
    skipOutro?: boolean
    skipConfirmationDialog?: boolean
}

const BaselineAssessment: FC<BaselineAssessmentProps> = ({
    onComplete,
    skipIntro = false,
    skipOutro = false,
    skipConfirmationDialog = false,
    ...stackProps
}) => {
    const intl = useIntl()
    const { userId } = useAppSelector((state) => state.user)
    const [step, setStep] = useState<number>(skipIntro ? 1 : 0)
    const [latestAssessmentResponse, setLatestAssessmentResponse] =
        useState<AssessmentAttemptSuccess>()

    const onAssessmentAttemptLoaded = useCallback(
        (attemptSuccess: AssessmentAttemptSuccess) => {
            Avo.surveyServed({
                featureType: 'assessment',
                activityType: 'onboarding_assessment_served',
                isOnboarding: true,
                assessmentId: attemptSuccess.assessmentId,
                assessmentAttemptId: attemptSuccess.assessmentAttemptId,
                assessmentName: attemptSuccess.name,
                assessmentVariant: attemptSuccess.variant,
                userId_: userId,
                surveyLocation: null
            })
            setLatestAssessmentResponse(attemptSuccess)
        },
        [userId]
    )

    const {
        loading,
        error,
        assessmentAttempt,
        assessmentItems,
        getInProgressOrCreateNewAttemptError
    } = useGetInProgressOrCreateNewAttempt(
        'baselineAssessment',
        'baseline',
        onAssessmentAttemptLoaded
    )

    function completeWithoutErrors() {
        Avo.surveyCompleted({
            featureType: 'assessment',
            activityType: 'onboarding_assessment_completed',
            isOnboarding: true,
            assessmentId: assessmentAttempt?.assessmentId,
            assessmentAttemptId: assessmentAttempt?.assessmentAttemptId,
            assessmentName: assessmentAttempt?.name,
            assessmentVariant: assessmentAttempt?.variant
        })
        onComplete({
            completed: true,
            error: error,
            specificError: getInProgressOrCreateNewAttemptError,
            assessmentAttempt: latestAssessmentResponse
        })
    }

    function completeWithErrors() {
        onComplete({
            completed: false,
            error: error,
            specificError: getInProgressOrCreateNewAttemptError,
            assessmentAttempt: latestAssessmentResponse
        })
    }

    const nextStep = () => {
        if (
            (skipOutro && step === assessmentItems?.length) ||
            step === assessmentItems?.length + 1
        ) {
            //if final step
            completeWithoutErrors()
        } else {
            //else next step
            if (step === 0) {
                //if first step
                Avo.surveyStarted({
                    featureType: 'assessment',
                    activityType: 'onboarding_assessment_started',
                    isOnboarding: true,
                    assessmentId: assessmentAttempt?.assessmentId,
                    assessmentAttemptId: assessmentAttempt?.assessmentAttemptId,
                    assessmentName: assessmentAttempt?.name,
                    assessmentVariant: assessmentAttempt?.variant
                })
            }
            setStep(step + 1)
        }
    }

    const prevStep = () => {
        setStep(step - 1)
    }

    const IntroSlide = () => (
        <Stack gap={3} mt={3} maxWidth={'600px'} alignSelf={'center'}>
            <CoreTypography variant={'h2'} component={'h1'}>
                {intl.formatMessage(messages.introHeader)}
            </CoreTypography>
            <img
                src={
                    'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/20df00fe-b41a-43c1-62ad-35eda1dd2300/cms'
                }
                alt={''}
                style={{
                    width: '300px',
                    maxWidth: '100%',
                    height: 'auto',
                    margin: 'auto'
                }}
            />
            <Stack>
                <CoreTypography variant={'h4'}>
                    {intl.formatMessage(messages.introSubHeader01)}
                </CoreTypography>
                <CoreTypography variant={'h4'}>
                    {intl.formatMessage(messages.introSubHeader02)}
                </CoreTypography>
            </Stack>
            <span>
                <Button
                    variant={'contained'}
                    endIcon={
                        <LeafIcon icon={'arrow-right'} fontSize={'small'} />
                    }
                    onClick={nextStep}
                    fullWidth={false}
                >
                    <CoreTypography customVariant={'buttonNormal'}>
                        {intl.formatMessage(messages.introButtonLabel)}
                    </CoreTypography>
                </Button>
            </span>
        </Stack>
    )

    const OutroSlide = () => (
        <Stack
            gap={3}
            mt={3}
            maxWidth={'700px'}
            alignSelf={'center'}
            alignItems={'center'}
        >
            <CoreTypography variant={'h2'} component={'h1'}>
                {intl.formatMessage(messages.outroHeader)}
            </CoreTypography>
            <ThriveLogoLoading stopOnCompletion />
            <CoreTypography variant={'h4'}>
                {intl.formatMessage(messages.outroSubHeader)}
            </CoreTypography>
            <Stack direction={'row'} gap={2}>
                <Button
                    variant={'contained'}
                    onClick={nextStep}
                    fullWidth={false}
                >
                    <CoreTypography customVariant={'buttonNormal'}>
                        {intl.formatMessage(messages.outroButtonLabel)}
                    </CoreTypography>
                </Button>
            </Stack>
            {/*TODO: add confirmation dialog*/}
        </Stack>
    )

    const isAssessmentItemStep =
        step > 0 && (step <= assessmentItems?.length || loading)

    return (
        <Stack
            display={'flex'}
            gap={2}
            textAlign={error ? 'left' : 'center'}
            {...stackProps}
        >
            {error ? (
                <LeafErrorCode
                    variant={ErrorScreenVariant.ServerError}
                    navigationButtonProps={{
                        onClick: completeWithErrors
                    }}
                />
            ) : (
                <>
                    {step === 0 && <IntroSlide />}
                    {isAssessmentItemStep && (
                        <>
                            <CoreTypography variant={'h4'} marginBottom={2}>
                                {intl.formatMessage(
                                    messages.assessmentItemDescriptor
                                )}
                            </CoreTypography>
                            {loading && (
                                <Stack
                                    mt={2}
                                    gap={2}
                                    alignItems={'center'}
                                    display={'flex'}
                                    width={'100%'}
                                >
                                    <Skeleton
                                        variant={'text'}
                                        sx={{ fontSize: '1.4rem' }}
                                        width={'80px'}
                                    />
                                    <Skeleton
                                        variant={'rounded'}
                                        sx={{ maxWidth: '100%' }}
                                        width={'600px'}
                                        height={'400px'}
                                    />
                                </Stack>
                            )}
                        </>
                    )}
                    {!loading && assessmentItems?.length > 0 && (
                        <>
                            {isAssessmentItemStep && (
                                <CoreTypography variant={'overline'}>
                                    {intl.formatMessage(
                                        messages.currentStepHeader,
                                        {
                                            currentStep: step,
                                            totalSteps: assessmentItems?.length
                                        }
                                    )}
                                </CoreTypography>
                            )}
                            {assessmentItems.map((assessmentItem, index) => {
                                return (
                                    <BaselineAssessmentItem
                                        key={index}
                                        assessmentItem={assessmentItem}
                                        step={step}
                                        prevStep={prevStep}
                                        nextStep={nextStep}
                                        assessmentAttempt={assessmentAttempt}
                                        setLatestAssessmentResponse={
                                            setLatestAssessmentResponse
                                        }
                                        skipConfirmationDialog={
                                            skipConfirmationDialog
                                        }
                                        sx={{
                                            mx: 0.5,
                                            visibility:
                                                index + 1 === step
                                                    ? 'visible'
                                                    : 'hidden',
                                            display:
                                                index + 1 === step
                                                    ? 'flex'
                                                    : 'none'
                                        }}
                                    />
                                )
                            })}
                        </>
                    )}
                    {step === assessmentItems?.length + 1 && !loading && (
                        <OutroSlide />
                    )}
                </>
            )}
        </Stack>
    )
}

export default BaselineAssessment
