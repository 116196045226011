import {
    AssessmentAttemptError,
    AssessmentAttemptSuccess,
    AssessmentStatus,
    useTryGetLatestAttemptQuery
} from '../graphql/generated/autogenerated'
import { ApolloError } from '@apollo/client'
import { useEffect, useState } from 'react'

type TryGetLatestAttemptHook = (
    assessmentName: string,
    assessmentVariant: string
) => {
    loading: boolean
    error: ApolloError
    refetch: () => Promise<any>
    isCompletedAttempt: boolean
    latestAttempt?: AssessmentAttemptSuccess
    getLatestAttemptError?: AssessmentAttemptError
}

export const useTryGetLatestAttempt: TryGetLatestAttemptHook = (
    assessmentName,
    assessmentVariant
) => {
    const { data, loading, error, refetch } = useTryGetLatestAttemptQuery({
        variables: {
            input: {
                name: assessmentName,
                variant: assessmentVariant
            }
        }
    })

    const [latestAttempt, setLatestAttempt] =
        useState<AssessmentAttemptSuccess>(undefined)
    const [isCompletedAttempt, setIsCompletedAttempt] = useState<boolean>(true)
    const [specificError, setSpecificError] =
        useState<AssessmentAttemptError>(undefined)

    useEffect(() => {
        if (data?.assessment?.tryGetLatestAttempt) {
            switch (data.assessment.tryGetLatestAttempt.__typename) {
                case 'AssessmentAttemptSuccess': {
                    const la = data.assessment.tryGetLatestAttempt
                    setLatestAttempt(la)
                    if (
                        la.status === AssessmentStatus.Completed ||
                        la.status === AssessmentStatus.Skipped
                    ) {
                        setIsCompletedAttempt(true)
                    } else {
                        setIsCompletedAttempt(false)
                    }
                    break
                }
                case 'AssessmentAttemptError': {
                    setSpecificError(data.assessment.tryGetLatestAttempt)
                    setIsCompletedAttempt(false)
                    break
                }
            }
        }
    }, [data])

    return {
        loading,
        error,
        refetch,
        isCompletedAttempt: isCompletedAttempt,
        latestAttempt: latestAttempt,
        getLatestAttemptError: specificError
    }
}
