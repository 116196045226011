import {
    Button,
    Card,
    CardContent,
    CardHeader,
    CardMedia,
    Icon,
    ImageListItem,
    ImageListItemBar,
    Stack
} from '@mui/material'
import {
    CoreTypography,
    LeafChip,
    LeafIcon,
    LeafScrim,
    Reset,
    ResetIframePlayer,
    ResetPlayerPopup
} from '@thriveglobal/thrive-web-leafkit'
import { formatPlaybackTime } from '../../../utils/formatPlaybackTime'
import React, { useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { ThriveReset } from '../../../graphql/generated/autogenerated'
import { useAppSelector } from '@thriveglobal/thrive-web-core'
import { CARD_PLACEHOLDER_IMAGE } from '../../../constants'

interface ProgressAssessmentCompletedCardProps {
    reset: ThriveReset
    loading: boolean
}

const messages = defineMessages({
    completedCardHeader: {
        defaultMessage: 'Thanks, {firstName}',
        description:
            "Title of a card telling the user 'thanks' for taking their assessment"
    },
    completedCardBody: {
        defaultMessage:
            "We'll ask you to reflect again in the coming weeks to make sure we're providing the best content for you. For now, here's a Reset we think you'll enjoy.",
        description:
            'Sub-title of a card showing the user a short video that is recommended for them after completing their assessment.'
    },
    watchButtonLabel: {
        defaultMessage: 'Watch',
        description: 'Label for a button that will open up a video player'
    },
    watchButtonAriaLabel: {
        defaultMessage: 'Start this Reset',
        description:
            'Accessible label for a button that will open up a video player and play a Thrive Reset'
    }
})
const ProgressAssessmentCompletedCard: React.FC<
    ProgressAssessmentCompletedCardProps
> = ({ reset, loading }) => {
    const intl = useIntl()
    const { fullName } = useAppSelector((state) => state.user)
    const firstName = fullName?.trim().split(' ')[0] ?? undefined

    const [isPlayerOpen, setIsPlayerOpen] = useState<boolean>(false)

    function handlePlayerOpen() {
        setIsPlayerOpen(true)
    }

    function handlePlayerClose() {
        setIsPlayerOpen(false)
    }

    function handleResetWatched() {
        //TODO: Add logic/tracking for reset watched
    }

    return (
        <Card>
            <CardHeader
                title={intl.formatMessage(messages.completedCardHeader, {
                    firstName: firstName
                })}
                titleTypographyProps={{ variant: 'h5' }}
            />
            <CardContent sx={{ pt: 0 }}>
                <CoreTypography variant={'body1'}>
                    {intl.formatMessage(messages.completedCardBody)}
                </CoreTypography>
            </CardContent>
            {loading ? (
                <CardMedia
                    image={CARD_PLACEHOLDER_IMAGE}
                    sx={{ height: '300px', width: '100%' }}
                />
            ) : (
                <LeafScrim
                    borderRadius="0"
                    leftScrimMaxWidth="75%"
                    overflow={'auto'}
                >
                    <ImageListItem sx={{ width: '100%' }}>
                        <CardMedia
                            image={reset?.landscape.thumbnail}
                            sx={{ height: '350px', width: '100%' }}
                        />
                        <ImageListItemBar
                            position="bottom"
                            title={
                                <Stack gap={1}>
                                    {reset?.durationInSeconds && (
                                        <LeafChip
                                            color={'primary'}
                                            label={formatPlaybackTime(
                                                intl,
                                                reset?.durationInSeconds
                                            )}
                                            sx={{ width: 'fit-content' }}
                                        />
                                    )}
                                    <CoreTypography variant={'h2'} mb={1}>
                                        {reset?.name}
                                    </CoreTypography>
                                </Stack>
                            }
                            subtitle={
                                <Stack gap={1}>
                                    <CoreTypography variant={'body1'}>
                                        {reset?.description}
                                    </CoreTypography>
                                    {reset?.themes &&
                                        reset.themes.length > 0 && (
                                            <Stack
                                                direction={'row'}
                                                gap={1}
                                                alignItems={'center'}
                                            >
                                                <Icon
                                                    component={Reset}
                                                    fontSize="medium"
                                                />
                                                <CoreTypography
                                                    variant={'overline'}
                                                    color={'inherit'}
                                                >
                                                    {reset.themes[0].theme}
                                                </CoreTypography>
                                            </Stack>
                                        )}
                                    <Button
                                        variant={'contained'}
                                        color={'secondary'}
                                        startIcon={
                                            <LeafIcon
                                                icon={'play'}
                                                fontSize={'small'}
                                            />
                                        }
                                        onClick={handlePlayerOpen}
                                        aria-label={intl.formatMessage(
                                            messages.watchButtonAriaLabel
                                        )}
                                        sx={{ width: 'fit-content' }}
                                    >
                                        <CoreTypography
                                            customVariant={'buttonNormal'}
                                        >
                                            {intl.formatMessage(
                                                messages.watchButtonLabel
                                            )}
                                        </CoreTypography>
                                    </Button>
                                </Stack>
                            }
                        />
                    </ImageListItem>
                </LeafScrim>
            )}
            <ResetPlayerPopup isOpen={isPlayerOpen} onClose={handlePlayerClose}>
                <ResetIframePlayer
                    src={reset?.landscape.iframe}
                    autoplay
                    controls
                    onEnded={handleResetWatched}
                />
            </ResetPlayerPopup>
        </Card>
    )
}

export default ProgressAssessmentCompletedCard
